import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "UniqValuesForm" }
const _hoisted_2 = { class: "flex-row flex-row-justify-end mb-6" }
const _hoisted_3 = { class: "mt-24 mr-15" }
const _hoisted_4 = ["onMouseenter", "data-idx", "onDragstart"]
const _hoisted_5 = { class: "mt-10 fw flex flex-row flex-row-justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_color_picker = _resolveComponent("color-picker")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_ui_icon = _resolveComponent("ui-icon")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_button, {
        onClick: _ctx.addRow,
        icon: "plus",
        type: "sucsses"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Добавить строку")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, idx) => {
      return (_openBlock(), _createBlock(_component_el_form, {
        inline: "",
        "label-position": "top",
        class: _normalizeClass({dragElement: idx === _ctx.draggingItemOrder })
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, { label: "Цвет" }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_color_picker, {
                  format: 'hex4',
                  "disable-alpha": "",
                  pureColor: row.color,
                  "onUpdate:pureColor": ($event: any) => ((row.color) = $event)
                }, null, 8, ["pureColor", "onUpdate:pureColor"])
              ])
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_el_form_item, { label: "Значение" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: row.value,
                "onUpdate:modelValue": ($event: any) => ((row.value) = $event)
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_el_form_item, {
            label: "Подпись",
            style: {"width":"330px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: row.label,
                "onUpdate:modelValue": ($event: any) => ((row.label) = $event)
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 2
          }, 1024),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_popconfirm, {
              title: "Удалить палитру?",
              "confirm-button-text": "Удалить",
              "confirm-button-type": "danger",
              width: "200",
              onConfirm: ($event: any) => (_ctx.rows.splice(idx, 1))
            }, {
              reference: _withCtx(() => [
                _createVNode(_component_el_button, {
                  circle: "",
                  type: "danger"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ui_icon, { name: "mdiDeleteOutline" })
                  ]),
                  _: 1
                })
              ]),
              _: 2
            }, 1032, ["onConfirm"])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["dragHandler", {hover:idx === _ctx.draggingItemOrder || _ctx.hoverIdx === idx}]),
            onMouseenter: ($event: any) => (_ctx.hoverIdx = idx),
            onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hoverIdx = -1)),
            draggable: true,
            "data-idx": idx,
            onDragstart: ($event: any) => (_ctx.dragStart(idx)),
            onDragend: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.dragEnd && _ctx.dragEnd(...args))),
            onDragover: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.dragOver && _ctx.dragOver(...args))),
            style: {"cursor":"grab"}
          }, [
            _createVNode(_component_ui_icon, { name: "mdiDrag" })
          ], 42, _hoisted_4)
        ]),
        _: 2
      }, 1032, ["class"]))
    }), 256)),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", null, [
        _createVNode(_component_el_button, {
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('cancel')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_popconfirm, {
          title: "Удалить текущий стандарт?",
          "confirm-button-text": _ctx.$t('delete'),
          "confirm-button-type": "danger",
          width: "200",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('delete')))
        }, {
          reference: _withCtx(() => [
            _createVNode(_component_el_button, { type: "danger" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('delete')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["confirm-button-text"])
      ]),
      _createVNode(_component_el_button, {
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('save',_ctx.rows.map((a)=> ({value:a.value,label: a.label, color: a.color,}))))),
        type: "primary"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('save')), 1)
        ]),
        _: 1
      })
    ])
  ]))
}
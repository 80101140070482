<template lang="pug" src="./VectorPaletteHistogram.pug"/>
<style lang="scss" src="./VectorPaletteHistogram.scss"/>

<script lang="ts">
import {
  computed, defineComponent, onMounted, onUnmounted, PropType, ref,
} from 'vue';
import { useUser } from '@/composables/useUser';
import { Goblet } from '@element-plus/icons-vue';
import { MapLayerUnifiedVectorModel } from '@/models/map/Layers/MapLayerUnifiedVectorModel';

export default defineComponent({
  name: 'VectorPaletteHistogram',
  components: { Goblet },
  props: {
    layer: {
      type: Object as PropType<MapLayerUnifiedVectorModel>,
      required: true,
    },
  },

  setup(props) {
    const { user } = useUser();

    const computedAccuracy = computed(() => Math.min(100, Object.keys(props.layer.palette.vectorData).length));

    const stepValue = computed(() => props.layer.palette.range.diff / computedAccuracy.value);

    const position = ref({
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    });
    // region tooltip
    const tooltipVisible = ref(false);
    const triggerRef = ref({
      getBoundingClientRect() {
        return position.value;
      },
    });

    const tooltipCount = ref(0);
    const tooltipValues = ref('');

    const barHover = (count: number, idx: number) => {
      tooltipCount.value = count;
      tooltipValues.value = `${(props.layer.palette.range.min + idx * stepValue.value).toFixed(2)} - ${(props.layer.palette.range.min + (idx + 1) * stepValue.value).toFixed(props.layer.palette.precision)}`;
      tooltipVisible.value = true;
    };

    const mousemoveHandler = (e: MouseEvent) => {
      position.value = DOMRect.fromRect({
        width: 0,
        height: 0,
        x: e.clientX,
        y: e.clientY,
      });
    };
    onMounted(() => {
      document.addEventListener('mousemove', mousemoveHandler);
    });

    onUnmounted(() => {
      document.removeEventListener('mousemove', mousemoveHandler);
    });
    // endregion

    const matrixChart = computed(() => {
      const matrix: number[] = Array(Math.ceil(computedAccuracy.value - 1)).fill(0);
      const arr = [...props.layer.palette.vectorData[props.layer.palette.property].values].sort((a, b) => (a.value > b.value ? 1 : -1));
      const expandedArray = arr.flatMap((item) => Array.from({ length: item.count }, () => item.value));
      expandedArray.forEach((v, i) => {
        const index = Math.floor((Number(v) - props.layer.palette.range.min) / stepValue.value);
        matrix[index] += 1;
      });
      return matrix;
    });

    const labels = computed(() => [
      props.layer.palette.range.min,
      props.layer.palette.range.min + (props.layer.palette.range.max - props.layer.palette.range.min) / 4,
      props.layer.palette.range.min + (props.layer.palette.range.max - props.layer.palette.range.min) / 2,
      props.layer.palette.range.min + (3 * (props.layer.palette.range.max - props.layer.palette.range.min)) / 4,
      props.layer.palette.range.max,
    ]);

    const maxCount = computed(() => matrixChart.value.reduce((acc, v) => {
      if (!Number.isNaN(v) && v > acc) {
        acc = v;
      }
      return acc;
    }, -Infinity));

    const reactivePaint = (v: number) => {
      const _value = props.layer.palette.range.min + v * (props.layer.palette.range.max - props.layer.palette.range.min);
      let color = props.layer.palette.borderData.values[0]?.color || '#F00';

      props.layer.palette.borderData.values.forEach((c) => {
        if (_value >= c.range.from && _value < c.range.to) {
          color = c.color;
        }
      });
      return color;
    };

    const barHeight = (count: number) => {
      const padding = 15;
      const maxHeight = 200 - padding;
      return (count * maxHeight) / maxCount.value;
    };

    return {
      computedAccuracy,
      user,
      matrixChart,
      reactivePaint,
      maxCount,
      barHeight,
      barHover,
      labels,
      triggerRef,
      tooltipVisible,
      tooltipCount,
      tooltipValues,
    };
  },
});
</script>

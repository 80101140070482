import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "MapContainer-ButtonsHorizontalGroup hide-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_tooltip, {
      placement: "right-start",
      "hide-after": 0,
      content: "Инструмент линейки"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["MapContainer-Button", { active:_ctx.isRulerActive }]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleRulerActive && _ctx.toggleRulerActive(...args)))
        }, [
          _createVNode(_component_ui_icon, {
            name: "mdiRuler",
            size: 24
          })
        ], 2)
      ]),
      _: 1
    }),
    _createVNode(_component_el_tooltip, {
      placement: "right-start",
      "hide-after": 0,
      content: "Инструмент расчета плошади"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["MapContainer-Button", { active:_ctx.isAreaActive }]),
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleAreaActive && _ctx.toggleAreaActive(...args)))
        }, [
          _createVNode(_component_ui_icon, {
            name: "mdiTextureBox",
            size: 24
          })
        ], 2)
      ]),
      _: 1
    })
  ]))
}
import tiffPacker from '@/lib/tiff/tiffPacker';
import { MapLayerCanvasModel } from '@/models/map/Layers/MapLayerCanvasModel';
import type { TaskCandidateModel } from '@/models/taskMap/TaskCandidateModel';
import { TaskCandidatesList } from '@/models/taskMap/TaskCandidatesList';
import FieldsList from '@/modules/fields/FieldsList';
import ApiService from '@/services/api/ApiService';
import { CreateBaseMapDto } from '@/services/api/dto/taskMap/CreateBaseMapDto';
import { computed, ref } from 'vue';

const candidates = ref<TaskCandidatesList>(new TaskCandidatesList());

const activeCandidate = ref<TaskCandidateModel>();

const createdCandidatesList = ref<number[]>([]);

const canvasEditor = ref<HTMLCanvasElement>();
const canvasEditorCtx = ref<CanvasRenderingContext2D | null>(null);

const canvasSnap = ref<HTMLCanvasElement>();
const canvasSnapCtx = ref<CanvasRenderingContext2D | null>(null);

const canvasCursorSquare = ref<HTMLCanvasElement>();
const canvasCursorSquareCtx = ref<CanvasRenderingContext2D | null >(null);

const _activeMode = ref<Record<number, 'selector' | 'form' | 'edit'>>({});

const activeGrid = ref(false);

const previewLayer = ref<MapLayerCanvasModel>();
const editorLayer = ref<MapLayerCanvasModel>();
const snapLayer = ref<MapLayerCanvasModel>();
const cursorLayer = ref<MapLayerCanvasModel>();

const _generalizationSize = ref(0.2);

const isAutoGeneralization = ref(false);

const step = ref<'field' | 'selector' | 'zones' | 'edit'>('field');

const settingsCopy = ref<{
  zones: string | null,
  sourceZones: string | null
}>({
  zones: null,
  sourceZones: null,
});

export const useCreateBaseMap = () => {
  /** Сформировать и отправить запрос на создание шаблона карты */
  const postBaseMap = () => {
    if (activeCandidate.value?.taskImage) {
      const image = activeCandidate.value?.taskImage;
      const data: CreateBaseMapDto = {
        bbox: image.bbox,
        candidate: activeCandidate.value?.id,
        field: activeCandidate.value?.fieldId,
        zones: image.formattedZones,
        name: activeCandidate.value?.inputName,
        payload: tiffPacker(Array.from(image.imageDataZoned)),
        height: image.scaledHeight,
        width: image.scaledWidth,
        folder: 0,
      };
      image.alreadyCreated = true;

      if (activeCandidate.value) {
        activeCandidate.value.isCreated = true;
      }
      ApiService.taskMap.createBaseMap(activeCandidate.value?.farmunit, data).then(() => {
        FieldsList.getFieldModel(activeCandidate.value?.fieldId)?.fetchTaskMaps(true);
      });
    }
  };

  const generalizationSize = computed({
    get: () => _generalizationSize.value,
    set: (v) => {
      _generalizationSize.value = v;
    },
  });

  const clearState = () => {
    candidates.value.clearState();
    createdCandidatesList.value = [];
    _activeMode.value = {};
    activeCandidate.value = undefined;
  };

  return {
    candidates,
    activeCandidate,
    createdCandidatesList,
    postBaseMap,
    canvasEditor,
    canvasEditorCtx,
    canvasSnap,
    canvasSnapCtx,
    canvasCursorSquare,
    canvasCursorSquareCtx,
    clearState,
    activeGrid,
    previewLayer,
    editorLayer,
    snapLayer,
    cursorLayer,
    isAutoGeneralization,
    generalizationSize,
    step,
    settingsCopy,
  };
};

import { Point } from 'geojson';
import { ScoutingPoiDto } from '@/services/api/dto/scouting/ScoutingPoiDto';
import { ScoutingPoiPhotoDto } from '@/services/api/dto/scouting/ScoutingPoiPhotoDto';
import ApiService from '@/services/api/ApiService';
import { useAuth } from '@/composables/useAuth';
import { SimpleType } from '@/constants/types/BasedTypes';
import { Model } from '@/models/Model';
import { OrderedPoiKeys } from '@/assets/data/OrderedPoiKeys';
import { cleanObject } from '@/utils/cleanObject';
import { POIParams } from '@/assets/data/POIParams';
import { POICategoryEnum } from '@/constants/enums/POICategoryEnum';
import PoiEvents from '@/modules/poi/PoiEvents';

/**
 * Словарь для переименовывание ключей старого образца в новый
 */
const filterObj = {
  Soil: 'soil_type',
  Soil_index: 'soil_index',
  Soiltextur: 'soiltextur',
  Parent_mat: 'parent_mat',
  Relief: 'relief',
  Gum_gor: 'humus_dept',
  HCl: 'hcl',
  St_pereupl: 'compact_dg',
  Pereupl_v: 'compact_h',
  Pereupl_n: 'compact_l',
  Water: 'water',
  Waterlog: 'waterlog',
  Add_hydrat: 'add_hydrat',
  Ins_hydrat: 'ins_hydrat',
  Erosion: 'erosion',
  Deflation: 'deflation',
  Stones: 'stones',
  Solonetz: 'solonetz',
  Salinizat: 'salinizat',
  Par_mat: 'par_mat',
  Is_visited: 'is_visited',
  N_prob: 'n_prob',
  Gumus_t: 'hms_t',
  'hms_%': 'hms_p',
  Compact: 'compact_dg',
  hg: 'hr',
  w: 'zpv',
  X: 'x',
  Y: 'y',
};

/** Набор ключей по которым нужна модификация значение со старого образца в новый стандарт */
const keysoil = ['waterlog', 'ins_hydrat', 'add_hydrat', 'salinizat', 'erosion', 'deflation', 'stones', 'solonetz', 'par_mat'];

export class PoiModel extends Model {
  get moveMode(): boolean {
    return this._moveMode;
  }

  set moveMode(value: boolean) {
    this._moveMode = value;
  }

  get field(): number | null {
    return this._field;
  }

  get image(): { src: string | undefined; alt: string | undefined } | undefined {
    return this._image;
  }

  get dto(): ScoutingPoiDto {
    return this._dto;
  }

  get farmunit(): number {
    return this._farmunit;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get photos(): ScoutingPoiPhotoDto[] {
    return this._photos;
  }

  get type(): number {
    return this._type;
  }

  set type(value: number) {
    this._type = value;
  }

  get params(): Record<string, SimpleType> {
    return this._params;
  }

  set params(value: Record<string, SimpleType>) {
    this._params = {
      'hms_%': value['Gumus_%'],
      hms_t: value?.Gumus_t,
      soil_type: value?.Soil,
      soil_index: value?.Soil_index,
      soiltextur: value?.Soiltextur,
      parent_mat: value.Parent_mat,
      relief: value?.Relief,
      humus_dept: value?.Gum_gor,
      hcl: value.HCl,
      compact_dg: value?.St_pereupl,
      compact_h: value?.Pereupl_v,
      compact_l: value?.Pereupl_n,
      water: value?.Water,
      waterlog: value?.Waterlog,
      add_hydrat: value?.Add_hydrat,
      ins_hydrat: value?.Ins_hydrat,
      erosion: value?.Erosion,
      deflation: value?.Deflation,
      stones: value?.Stones,
      solonetz: value?.Solonetz,
      salinizat: value?.Salinizat,
      par_mat: value?.Par_mat,
      is_visited: value?.Is_visited,
      ...value,
    };
  }

  get isActive(): boolean {
    return this._isActive;
  }

  set isActive(value: boolean) {
    this._isActive = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  set group(value: number) {
    this._group = value;
  }

  get group(): number {
    return this._group;
  }

  get geometry(): Point {
    return this._geometry;
  }

  set id(value: number) {
    this._id = value;
  }

  get id(): number {
    return this._id;
  }

  set visited(value: boolean) {
    this._visited = value;
  }

  get visited(): boolean {
    return this._visited;
  }

  set isMoving(value: boolean) {
    this._isMoving = value;
  }

  get isMoving(): boolean {
    return this._isMoving;
  }

  set x(value: number) {
    this._geometry.coordinates[0] = value;
  }

  get x(): number {
    return this._geometry.coordinates[0];
  }

  set y(value: number) {
    this._geometry.coordinates[1] = value;
  }

  get y(): number {
    return this._geometry.coordinates[1];
  }

  private _id: number;

  protected _geometry: Point;

  private _farmunit: number;

  private _isMoving = false;

  private _isActive = false;

  private _dto: ScoutingPoiDto;

  private _visited: boolean;

  private _group: number;

  private _name: string;

  private _params: Record<string, SimpleType>;

  private _type: number;

  private _photos: ScoutingPoiPhotoDto[];

  private _description: string;

  private _image: {
    src: undefined | string,
    alt: undefined | string,
  } | undefined = undefined;

  private _field: number | null ;

  private _moveMode = false;

  constructor(value: ScoutingPoiDto) {
    super();
    this._dto = value;
    this._id = value.id;
    this._farmunit = value.farmunit;
    this._geometry = { ...value.geometry };
    this._visited = value.params.is_visited || value.params.Is_visited || false;
    this._group = value.poigroup || 0;
    this._name = value.name || '';
    this._params = this.formatOldInNew(value.params);
    this._type = value.poitype;
    this._photos = value.photos_list;
    this._description = value.descr || '';
    this._field = value.field;
    if (this._photos && this._photos.length) {
      this._image = {
        src: `${process.env.VUE_APP_API_URL}/scouting/photo/${this._photos[0].id}/file/?size=70&access_token=${useAuth().accessToken.value}`,
        alt: this._photos[0].name,
      };
    }
  }

  update(value: ScoutingPoiDto) {
    this._dto = value;
    this._geometry = { ...value.geometry };
    this._visited = value.params.is_visited || value.params.Is_visited || false;
    this._group = value.poigroup || 0;
    this._name = value.name || '';
    this._params = this.formatOldInNew(value.params);
    this._type = value.poitype;
    this._photos = value.photos_list;
    this._description = value.descr || '';
    this._field = value.field;
    if (this._photos && this._photos.length) {
      this._image = {
        src: `${process.env.VUE_APP_API_URL}/scouting/photo/${this._photos[0].id}/file/?size=70&access_token=${useAuth().accessToken.value}`,
        alt: this._photos[0].name,
      };
    }
    PoiEvents.emitUpdatePoiLabel();
  }

  formatOldInNew(params: any) {
    const obj = {
      x: params?.X || this._geometry.coordinates[0],
      y: params?.Y || this._geometry.coordinates[1],
      n_prob: this._name,
      'hms_%': params['Gumus_%'],
      hms_t: params?.Gumus_t,
      soil_type: params?.Soil,
      soil_index: params?.Soil_index,
      soiltextur: params?.Soiltextur,
      parent_mat: params.Parent_mat,
      relief: params?.Relief,
      humus_dept: params?.Gum_gor,
      hcl: params?.HCl,
      compact_dg: params?.St_pereupl,
      compact_h: params?.Pereupl_v,
      compact_l: params?.Pereupl_n,
      water: params?.Water,
      waterlog: params?.Waterlog,
      add_hydrat: params?.Add_hydrat,
      ins_hydrat: params?.Ins_hydrat,
      erosion: params?.Erosion,
      deflation: params?.Deflation,
      stones: params?.Stones,
      solonetz: params?.Solonetz,
      salinizat: params?.Salinizat,
      par_mat: params?.Par_mat,
      is_visited: params?.Is_visited,
      ...params,
    };

    Object.keys(filterObj).forEach((k) => {
      delete obj[k];
    });

    Object.keys(filterObj).forEach((item) => {
      // @ts-ignore
      const value = filterObj[item];

      if (value !== undefined) {
        if (keysoil.some((i) => i === value && ['true', true].some((x) => x === obj[value]))) {
          obj[value] = 1;

          delete obj[item];
        } else if (obj[value] === '*') {
          obj[value] = 1;
          delete obj[item];
        } else if (obj[value] === '**') {
          obj[value] = 2;
          delete obj[item];
        } else if (obj[value] === '***') {
          obj[value] = 3;
          delete obj[item];
        }
      }
    });

    const sortedObject: Record<string, any> = {};

    OrderedPoiKeys.forEach((key) => {
      if (!(obj[key] === 0 && POIParams[key].category === POICategoryEnum.LIMIT_FACTOR)) {
        if (obj.hasOwnProperty(key)) {
          sortedObject[key] = obj[key];
        }
      }
    });

    // Добавляем дополнительные ключи в конец отсортированного объекта
    Object.keys(obj).forEach((key) => {
      if (!OrderedPoiKeys.includes(key)) {
        sortedObject[key] = obj[key];
      }
    });

    return cleanObject(sortedObject);
  }

  getFormData() {
    const formData = new FormData();
    formData.append('farmunit', this.farmunit.toString());
    formData.append('name', this.name);
    formData.append('geom', `SRID=4326;POINT (${this.x} ${this.y})`);
    formData.append('poigroup', this.group.toString());
    formData.append('descr', this.description);
    formData.append('poitype', this.type.toString());
    formData.append('params', JSON.stringify(this._params));
    return formData;
  }

  async saveModel(ignoreLog = false) {
    await ApiService.scouting.putPoiEdit(this.id, this.getFormData(), ignoreLog);
  }

  deletePhoto(id: number) {
    this._photos = this._photos.filter((p) => p.id !== id);
  }

  async saveMove(): Promise<void> {
    const formData = new FormData();
    formData.append('geom', `SRID=4326;POINT (${this.x} ${this.y})`);
    await ApiService.scouting.putPoiEdit(this.id, formData);
  }

  async delete(): Promise<void> {
    this.isActive = false;
    await ApiService.scouting.deletePoi(this.id);
  }

  move(coords: [number, number]): void {
    this._geometry.coordinates = coords;
  }

  static formatPoiValue = (value: SimpleType, type: string) => {
    if (type === 'boolean') {
      return value === 'true';
    }

    if (['number', 'int'].includes(type)) {
      const v = Number.isNaN(parseFloat(String(value))) ? -1 : parseFloat(String(value));
      if (v === -1) {
        return null;
      }
      return type === 'number' ? v : Math.round(v);
    }
    if (type === 'stars') {
      return Number(value);
    }
    if (!value) {
      return '';
    }
    return String(value);
  };
}

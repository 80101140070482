import { PaletteType } from '@/constants/types/palette/PaletteType';
import {
  BorderDataType,
  BorderValuesType,
  UniqDataType,
  UniqValuesType,
} from '@/constants/types/palette/UnifiedVectorPaletteType';
import { Model } from '@/models/Model';
import ApiService from '@/services/api/ApiService';
import { PaletteDto } from '@/services/api/dto/assets/palette/PaletteDto';
import AssetsGradients from '@/services/assets/AssetsGradients';
import LoggerService from '@/services/logger/LoggerService';
import { jenks } from '@/utils/jenks';
import { pythonRound } from '@/utils/pythonRound';
import chroma from 'chroma-js';
import { quantileSeq } from 'mathjs';
import { reactive } from 'vue';
import { VectorKeyType } from '@/constants/types/VectorKeyType';
import ShapeStandardList from '@/modules/assets/shapeStandard/ShapeStandardList';
import { ShapeStandardModel } from '@/models/assets/ShapeStandardModel';
import { ElNotification } from 'element-plus';

export class PaletteModel extends Model {
  public type: PaletteType | null = null;

  public uniqData: UniqDataType = reactive({ values: [] as UniqValuesType[] } as UniqDataType);

  public borderData: BorderDataType = reactive({ classCount: 3, classification: 'equal', values: [] });

  public readonly vectorData: Record<string, VectorKeyType>;

  private _rev: string | undefined;

  constructor(vectorData: Record<string, VectorKeyType> | undefined, dto: PaletteDto | undefined) {
    super();
    this.vectorData = vectorData;

    if (AssetsGradients.data.length === 0) {
      LoggerService.error('Gradients list is empty..');
    }

    this._id = dto?._id ? dto._id : this.uuid;

    this._rev = dto?._rev ? dto._rev : undefined;

    if (dto?.type) {
      this.type = dto.type;
    } else {
      this.type = 'uniqValues';
    }

    if (dto?.property) {
      this._property = dto.property;
    }

    if (this.type === 'borderValues' && dto !== undefined) {
      this.borderData = dto.data as BorderDataType;
    } else {
      this.calculateBorderValues();
    }

    if (this.type === 'uniqValues' && dto !== undefined) {
      this.uniqData = dto.data as UniqDataType;
    } else {
      this.calculateUniqueValues();
    }
  }

  private _id: string;

  get id(): string {
    return this._id;
  }

  private _property: string | null = null;

  get property(): string | null {
    return this._property;
  }

  set property(value: string | null) {
    this._property = value;
    if (this.vectorData[value].number) {
      this.calculateBorderValues();
    }
    this.calculateUniqueValues();
  }

  get values(): Array<string | number | null> {
    return this.vectorData[this.property].values.flatMap((v) => [].fill(v.value, 0, v.count) as (string | number | null)[]);
  }

  get range(): { min: number, max: number, diff: number} | undefined {
    return this.vectorData[this.property]?.number ? {
      min: this.vectorData[this.property].number.min,
      max: this.vectorData[this.property].number.max,
      diff: this.vectorData[this.property].number.max - this.vectorData[this.property].number.min,
    } : undefined;
  }

  get precision(): number | undefined {
    return this.vectorData[this.property]?.number.precision;
  }

  get isNumber(): boolean {
    return !!this.vectorData[this.property]?.number || false;
  }

  get min(): number | undefined {
    return this.vectorData[this.property]?.number.min;
  }

  get max(): number | undefined {
    return this.vectorData[this.property]?.number.max;
  }

  updateBorderValues(stops: number[]): void {
    if (stops.length === this.borderData.classCount + 1) {
      for (let i = 0; i < (this.borderData.classCount || 3); i++) {
        this.borderData.values[i].range.from = stops[i];
        this.borderData.values[i].range.to = stops[i + 1];
        if (i === 0 && /^(< (\d|\.)*)$/.test(this.borderData.values[i].label)) {
          this.borderData.values[i].label = `< ${stops[i + 1]}`;
        } else if (i === this.borderData.classCount - 1 && /^(> (\d|\.)*)$/.test(this.borderData.values[i].label)) {
          this.borderData.values[i].label = `> ${stops[i]}`;
        } else if (/^((\d|\.)* - (\d|\.)*)$/.test(this.borderData.values[i].label)) {
          this.borderData.values[i].label = `${stops[i]} - ${stops[i + 1]}`;
        }
      }
    } else {
      LoggerService.error('Update border values called with out of range array length.', stops, `Expected array with length: ${this.borderData.classCount + 1}`);
    }
  }

  getGradientColor(stops: number[], idx: number): string {
    const f = chroma
      .scale(this.getGradient().positions.map((p) => p.color))
      .domain(this.getGradient().positions.map((p) => p.position));
    return f(stops[idx]).toString();
  }

  calculateUniqueValues(): void {
    if (this.property) {
      try {
        this.uniqData.values.splice(0, Infinity);
      } catch (e) {
        //
      }
      this.uniqData.values = [];

      const property = this.property.toString();

      const standardModel: ShapeStandardModel = ShapeStandardList.data.find((a) => a.id === this._property && a.type === 'uniqValues');
      if (standardModel) {
        this.type = 'uniqValues';
        const data = standardModel.data as UniqValuesType[];

        data.filter(((a) => this.vectorData[property].values.some((b) => a.value.toString() === b.value.toString()))).forEach((v, i) => {
          this.uniqData.values.push({
            value: v.value,
            label: v.label || v.value.toString(),
            color: v.color,
          });
        });
        return;
      }

      const count = Object.keys(this.vectorData[property].values).length || 0;
      this.vectorData[property].values.forEach((v, i) => {
        this.uniqData.values.push({
          value: v.value,
          label: v.value?.toString() || '',
          color: this.getGradientColor(this.calculateGradientStops(count), i),
        });
      });

      this.uniqData.gradient = {
        id: this.getGradient().id,
        stops: this.calculateGradientStops(count),
      };
    }
  }

  calculateBorderValues(): void {
    if (!this.vectorData[this.property] || this.vectorData[this.property]?.number === null) {
      return;
    }
    const _ranges = [];
    const precision = this.property ? this.precision[this.property] : 0;

    const standardModel: ShapeStandardModel = ShapeStandardList.data.find((a) => a.id === this._property && a.type === 'borderValues');
    if (standardModel) {
      this.type = 'borderValues';
      const data = standardModel.data as BorderValuesType[];
      this.borderData.classification = 'custom';
      this.borderData.values.splice(0, Infinity);
      let isFirst = true;
      data.forEach((v, idx) => {
        if (v.range.to < this.vectorData[this.property].number.min) {
          return;
        }
        if (v.range.from > this.vectorData[this.property].number.max) {
          return;
        }
        if (isFirst) {
          this.borderData.values.push({
            color: v.color,
            label: /^(< (\d|\.)*)$/.test(v.label) ? `${this.vectorData[this.property].number.min} - ${v.range.to}` : v.label,
            range: {
              from: this.vectorData[this.property].number.min,
              to: v.range.to === Infinity ? this.vectorData[this.property].number.max : v.range.to,
            },
          });
          isFirst = false;
        } else if (idx === data.length - 1) {
          this.borderData.values.push({
            color: v.color,
            label: v.label,
            range: {
              from: v.range.from === -Infinity ? this.vectorData[this.property].number.min : v.range.from,
              to: this.vectorData[this.property].number.max,
            },
          });
        } else {
          this.borderData.values.push({
            color: v.color,
            label: /^(< (\d|\.)*)$/.test(v.label) ? `${v.range.from} - ${v.range.to}` : v.label,
            range: {
              from: v.range.from,
              to: v.range.to,
            },
          });
        }
      });
      this.borderData.classCount = this.borderData.values.length;
      return;
    }

    if (this.borderData.classification === 'custom') {
      this.borderData.classification = 'equal';
    }

    for (let i = 0; i < this.borderData.classCount; i++) {
      const range = {
        min: this.vectorData[this.property].number.min,
        max: this.vectorData[this.property].number.max,
        diff: this.vectorData[this.property].number.max - this.vectorData[this.property].number.min,
      };
      if (this.borderData.classification === 'equal') {
        _ranges.push({
          from: pythonRound(range.min + i * (range.diff / this.borderData.classCount), precision),
          to: pythonRound(range.min + (i + 1) * (range.diff / this.borderData.classCount), precision),
        });
      } else if (this.borderData.classification === 'jenkins') {
        const values: number[] = this.property ? this.values.map((v: string | number) => Number(v)) : [];
        let _prev: number | null = null;
        [range.min, ...(jenks(values, this.borderData.classCount - 1) as number[])].forEach((stop) => {
          if (_prev !== null) {
            _ranges.push({
              from: pythonRound(_prev, precision),
              to: pythonRound(stop, precision),
            });
          }
          _prev = stop;
        });
      } else if (this.borderData.classification === 'quantile') {
        const values: number[] = this.property ? this.values.map((v: string | number) => Number(v)) : [];
        const qs = [];
        for (let a = 1; a < this.borderData.classCount; a++) {
          qs.push(quantileSeq(values, a / (this.borderData.classCount - 1)));
        }
        let _prev: number | null = null;
        [range.min, ...qs].forEach((stop) => {
          if (_prev !== null) {
            _ranges.push({
              from: pythonRound(_prev, precision),
              to: pythonRound(stop, precision),
            });
          }
          _prev = stop;
        });
      }
    }
    this.borderData.values.splice(0, Infinity);
    for (let i = 0; i < this.borderData.classCount; i++) {
      this.borderData.values.push({
        color: this.getGradientColor(this.calculateGradientStops(this.borderData.classCount), i),
        label: `${_ranges[i].from} - ${_ranges[i].to}`,
        range: _ranges[i],
      });

      this.borderData.gradient = {
        id: this.getGradient().id,
        stops: this.calculateGradientStops(this.borderData.classCount),
      };
    }
  }

  toJSON(): boolean | PaletteDto {
    if (!this.type) {
      return false;
    }
    if (!this.property) {
      return false;
    }
    let data: UniqDataType | BorderDataType | undefined;
    if (this.type === 'uniqValues') {
      data = this.uniqData;
    } else if (this.type === 'borderValues') {
      data = this.borderData;
    }
    if (!data) {
      return false;
    }
    const json: PaletteDto = {
      _id: this._id.startsWith('default') ? this.uuid : this._id,
      type: this.type,
      property: this.property,
      _rev: undefined,
      data,
    };
    if (this._rev) {
      json._rev = this._rev;
    }
    return json as PaletteDto;
  }

  public async save(): Promise<void> {
    const json = this.toJSON();
    if (json) {
      const { data } = await ApiService.assets.putPalette(json as PaletteDto);
      if (data.ok) {
        if (data.id !== this._id) {
          this._id = data.id;
        }
        this._rev = data.rev;
      } else {
        ElNotification({
          title: 'Ошибка синхронизации',
          message: 'Локальная версия настройки слоя не совпадает с версией на нашем сервере. Возможно кто-то ещё изменил настройки слоя или вы давно не обновляли страницу. Попробуйте обновить страницу и повторить настройку.',
          type: 'error',
          position: 'bottom-right',
        });
      }
    }
  }

  private calculateGradientStops(count: number) {
    const stops: number[] = [];

    if (count <= 1) {
      stops.push(0);
      stops.push(100);
    } else {
      for (let i = 0; i < count; i++) {
        stops.push((i * 100) / (count - 1));
      }
    }
    return stops;
  }

  private getGradient() {
    let id: string | undefined;
    if (this.type === 'borderValues') {
      id = this.borderData?.gradient?.id;
    } else if (this.type === 'uniqValues') {
      id = this.uniqData?.gradient?.id;
    }
    return AssetsGradients.data.find((value) => value.id === id) || AssetsGradients.data[0];
  }
}
